const firebaseConfig = {
  apiKey: "AIzaSyCCivTXJvnu7v2V4MZixjVTCDUe6IGap28",
  authDomain: "rendezvouscloudlms.firebaseapp.com",
  projectId: "rendezvouscloudlms",
  storageBucket: "rendezvouscloudlms.appspot.com",
  messagingSenderId: "250893516171",
  appId: "1:250893516171:web:366f4bb25d875d2d0c838e"
};


// const firebaseConfig = {
//   apiKey: "AIzaSyAdVUnSGa_uG89pCMSQe0AdAPL5EIdbvZE",
//   authDomain: "capstone2-rendezvous.firebaseapp.com",
//   projectId: "capstone2-rendezvous",
//   storageBucket: "capstone2-rendezvous.appspot.com",
//   messagingSenderId: "1000020258440",
//   appId: "1:1000020258440:web:55d64d3a469ac8f460f93d",
//   measurementId: "G-G7XHZ350SS"
// };
  
export default firebaseConfig;
// const firebaseConfig = {
//   apiKey: "AIzaSyBdfY8SyxM9VVeBWQGPEJ9bEF_cnarDy38",
//   authDomain: "rendezvous-lms.firebaseapp.com",
//   projectId: "rendezvous-lms",
//   storageBucket: "rendezvous-lms.appspot.com",
//   messagingSenderId: "739317264672",
//   appId: "1:739317264672:web:03df8338d0856444be6745",
//   measurementId: "G-0NWDZY23VN"
// };
  
// export default firebaseConfig;